var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('PageHeader',{attrs:{"title":"Optouts"}}),_c('section',[_c('button',{directives:[{name:"modal",rawName:"v-modal",value:({
          target: 'create-optouts-modal',
        }),expression:"{\n          target: 'create-optouts-modal',\n        }"}],staticClass:"btn btn-success btn-add-optout"},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Novo ")]),_c('div',{staticClass:"config"},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary",class:{
            'qt-loader qt-loader-mini qt-loader-right': _vm.isDownloading,
          },attrs:{"disabled":_vm.isDownloading},on:{"click":_vm.exportOptout}},[_c('i',{staticClass:"fas fa-gear"}),_vm._v(" "+_vm._s(_vm.$t('generic-str.export'))+" ")]),_c('button',{directives:[{name:"modal",rawName:"v-modal",value:({
            target: 'optout-logo-modal',
            data: { newlogo: _vm.newlogo },
          }),expression:"{\n            target: 'optout-logo-modal',\n            data: { newlogo },\n          }"}],staticClass:"btn btn-secondary",on:{"click":_vm.callOptoutLogo}},[_c('i',{staticClass:"fas fa-gear"}),_vm._v(" "+_vm._s(_vm.$t('generic-str.logo'))+" ")])])])]),_c('section',{staticClass:"page-content container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('opted-card')],1)])]),_c('create-optouts-modal',{attrs:{"id":"create-optouts-modal"},on:{"submit":function($event){return _vm.fetchAll(1)}}}),_c('optouts-modal',{attrs:{"id":"optouts-modal"},on:{"submit":function($event){return _vm.fetchAll(1)}}}),_c('optout-setting-modal',{attrs:{"id":"optout-setting-modal"}}),_c('optout-logo-modal',{attrs:{"id":"optout-logo-modal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }